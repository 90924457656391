






















































































import { Vue, Component } from 'vue-property-decorator';
import UserApi from '@/services/user';
import { EnumRole, User, UserRegistrationRequestByAdmin } from '@/models/user';
import store from '@/store';

@Component
export default class Users extends Vue {
  userApi = new UserApi();

  userFields: { text: string, value: string }[] = [
    { text: 'email', value: 'email' },
    { text: 'username', value: 'username' },
    { text: 'role', value: 'role' },
    { text: '', value: '' },
  ];

  users: User[] | null = null;

  isModalCreateUserOpen = false;

  isModalDeleteUserOpen = false;

  userToDelete: number | null = null;

  availableRoles = [EnumRole.ADMIN, EnumRole.EDITOR, EnumRole.USER];

  newUser: UserRegistrationRequestByAdmin = {
    email: '',
    username: '',
    password: '',
    verified: true,
    role: EnumRole.USER,
  }

  // todo: vee-validate
  newUserPasswordValidation = '';

  created(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    store.dispatch('setLoading', true);
    this.userApi.findAll().then((response) => {
      if (response.success) {
        this.users = response.result;
        console.log('r', this.users);
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
    }).finally(() => {
      store.dispatch('setLoading', false);
    });
  }

  createUser(): void {
    store.dispatch('setLoading', true);
    this.userApi.create(this.newUser).then((response) => {
      if (response.success) {
        console.log('success', response);
        this.isModalCreateUserOpen = false;
        this.loadUsers();
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
      store.dispatch('setLoading', false);
    });
  }

  showDeleteUserModal(id: number): void {
    this.userToDelete = id;
    this.isModalDeleteUserOpen = true;
  }

  deleteUser(): void {
    store.dispatch('setLoading', true);
    this.userApi.remove(this.userToDelete!).then((response) => {
      if (response.success) {
        console.log('success', response);
        this.isModalDeleteUserOpen = false;
        this.loadUsers();
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
      store.dispatch('setLoading', false);
    });
  }
}
