import Api from './api';
import { User, UserRegistrationRequestByAdmin } from '@/models/user';
import { ServerResponse } from '@/models/response';

export default class UserApi extends Api {
  public findAll(): Promise<ServerResponse<User[]>> {
    const url = '/api/user';

    return this.get<ServerResponse<User[]>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public create(userData: UserRegistrationRequestByAdmin): Promise<ServerResponse<User>> {
    const url = '/api/user';

    return this.post<UserRegistrationRequestByAdmin, ServerResponse<User>>(url, userData).then((response) => {
      const { data } = response;

      return data;
    });
  }

  public remove(userId: number): Promise<ServerResponse<null>> {
    const url = `/api/user/${userId}`;

    return this.delete<ServerResponse<null>>(url).then((response) => {
      const { data } = response;

      return data;
    });
  }
}
